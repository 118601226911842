@import './_include-media';
@import './common';


// Import Bootstrap and its default variables
// @import '~bootstrap/scss/bootstrap.scss';

body, html, body >:global(#root) {
	height:100%;
	// overflow: auto;
	display: flex;
	flex-direction: column;

	font-family: 'Roboto', $font-family-base;

	-webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

	// $mask: linear-gradient(to bottom,
	// 		rgba(0, 0, 0, 0) 0rem,
	// 		rgba(0, 0, 0, 1) 0rem,
	// 		rgba(0, 0, 0, 1) 95.5%,
	// 		rgba(0, 0, 0, 0) 100%);

	// mask-image: $mask;
	// -webkit-mask-image: $mask;
}

body {
	overflow: auto;
	min-height: 100vh; // min-height required for safari
	height: auto; // required for safari

	// background-image: linear-gradient(to bottom, #FFFAFF, #90335a, #4b235d, #312783, #29235C ) !important;

	// background-color: rgb(255,250,255);
	background-color: black !important;

	// @include customIgOverlayLight();
	// @include customIgOverlayLight();

	color: $content-color;
}

// Add padding for notches to dialogs
body:global(.notched) {
	:global(.MuiDialog-paper) {
		margin-top: $notch-size + 1.5rem;
	}

	:global(.MuiDialog-paperFullScreen) {
		margin-top: $notch-size;
		height: calc(100% - #{$notch-size});
	}
}

:global(.MuiSkeleton-root) {
	background: transparentize($color: $content-color, $amount: 0.9) !important;
}

:global(.MuiFormLabel-root),
:global(.MuiSelect-icon) {
	// color: rgba(255,255,255,0.54) !important;
	color: transparentize($content-color, 0.44);
}

:global(.MuiInput-root),
:global(.MuiIconButton-root),
:global(.MuiButton-root) {
	color: $content-color !important;
}

:global(.MuiIconButton-root):global(.MuiButton-textPrimary),
:global(.MuiButton-root):global(.MuiButton-textPrimary) {
	color: darken($highlight-color, 1%) !important;
}

:global(.MuiInput-underline):hover:not(:global(.Mui-disabled)):before,
:global(.MuiInput-underline:before) {
	// border-color: rgba(255,255,255,0.47) !important;
	border-color: transparentize($content-color, 0.53) !important;
}

:global(.MuiTypography-colorTextSecondary) {
	// color: rgba(255,255,255,0.77) !important;
	color: transparentize($content-color, 0.33) !important;
}

:global(.MuiCard-root),
:global(.MuiPaper-root),
:global(.MuiDialog-paper) {

	:global(.MuiFormLabel-root),
	:global(.MuiSelect-icon) {
		color: rgba(0,0,0,0.54) !important;
		// color: transparentize($content-bg, 0.53) !important;
	}

	:global(.MuiInput-root),
	:global(.MuiIconButton-root),
	:global(.MuiButton-root) {
		color: rgba(0,0,0,0.9) !important;
	}


	:global(.MuiIconButton-root):global(.MuiButton-textPrimary),
	:global(.MuiButton-root):global(.MuiButton-textPrimary) {
		color: darken($highlight-color, 1%) !important;
	}

	:global(.MuiButton-containedPrimary) {
		color: $foreground-color !important;
	}

	:global(.MuiInput-underline):hover:not(:global(.Mui-disabled)):before,
	:global(.MuiInput-underline:before) {
		border-color: rgba(0,0,0,0.47) !important;
	}

	:global(.MuiInput-underline):hover:not(:global(.Mui-disabled)):after,
	:global(.MuiInput-underline:after) {
		border-color: $highlight-color !important;
	}
	
	
	:global(.MuiTypography-colorTextSecondary) {
		color: rgba(0,0,0,0.77) !important;
	}
}

:global(.MuiDialog-paperFullScreen) {
	background: black !important;
	color: white !important;

	:global(.MuiFormLabel-root),
	:global(.MuiSelect-icon) {
		color: rgba(255,255,255,0.54) !important;
	}

	:global(.MuiInput-root),
	:global(.MuiIconButton-root),
	:global(.MuiButton-root) {
		color: $foreground-color !important;
	}

	:global(.MuiIconButton-root):global(.MuiButton-textPrimary),
	:global(.MuiButton-root):global(.MuiButton-textPrimary) {
		color: darken($highlight-color, 1%) !important;
	}

	:global(.MuiInput-underline):hover:not(:global(.Mui-disabled)):before,
	:global(.MuiInput-underline:before) {
		border-color: rgba(255,255,255,0.47) !important;
	}

	:global(.MuiInput-underline):hover:not(:global(.Mui-disabled)):after,
	:global(.MuiInput-underline:after) {
		border-color: $highlight-color !important;
	}
	

	:global(.MuiTypography-colorTextSecondary) {
		color: rgba(255,255,255,0.77) !important;
	}
}


// Modify text muted to comply with our styling
:global(.text-muted) {
	// !important necessary to override bootstrap's _text.scss:19 decl
	color: $content-color !important; //transparentize($content-color, .55) !important;
	filter: opacity(.55);
	line-height: 1.2;
}

:global(.btn) {
	color: transparentize($content-color, .2);
}

// html {
// 	font-size: 30px;
// }

// @include media('retina2x') {
// 	html {
// 		font-size: 22px;

// 		@media (min-width: 768px) {
// 			font-size: 32px;
// 		}
// 	}
// }

// @include media('retina3x') {
// 	html {
// 		font-size: 22px;
// 	}
// }

/**************************************************************************/

// Per https://css-tricks.com/snippets/css/turn-off-number-input-spinners/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/**************************************************************************/

input[type=color] {
	min-height: 2.35rem;
	padding: 0.25rem;
}

/**************************************************************************/


:global(.btn) {
	@include stylize-button($content-bg);

	&:global(.btn-success) {
		@include stylize-button($brand-primary);
	}
}

/**************************************************************************/

:global(a) {
	color: lighten($brand-primary, 20%);
	&:hover, &:active, &:focus {
		color: lighten($brand-primary, 5%);
	}
}