@import '../styles/common.scss';
@import './chatCommon.scss';

.root {
    background: $highlight-color;// darken(invert($foreground-color), 5%);
    
    // box-shadow: 0 0px 1rem rgba(0,0,0,0.5);
    padding: 0.25rem;// 0.5rem .25rem 0.25rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    // 4px is MUI standard
    $input-border-radius: 4px; //0.125rem;
    position: relative;

    .uploadWidget {
        position: absolute;

        transform: translateX(-50%);
        left: 50%;
        top: -8.05rem;
        z-index: 100;

        max-width: 50vh;
        width: 20rem;
        height: 10rem;

        box-shadow: 0 0 2rem transparentize($highlight-color, .1);
        // padding: 1rem;

        border-radius: $input-border-radius;
        border: 1px solid transparentize(darken($highlight-color, 20%), .2);
        background: transparentize($highlight-color, .2);
        backdrop-filter: blur(6px);
        color: white;

        display: flex;
        flex-direction: column;
        
        section > div {
            height: 100%;
            padding: 1.5rem 1rem .5rem;
        }
        
        section {
            cursor: pointer;
            flex: 1;
            // border-radius: $input-border-radius;
            background: rgba(255,255,255,0.125);

            text-align: center;
        }

        .cancelButton {
            height: 2rem;
            width: 100%;
            background: transparentize(darken($highlight-color, 20%), .2);
        }
    }

    textarea {
        background: $content-bg;
        color: $content-color;

        font-family: $font-family-base;
        // font-size: .75rem;
        font-size: $text-size;

        border-radius: $input-border-radius;
        padding: .25rem .5rem;
        // flex: 1;

        // width: calc(100% - 0rem);
        width: 100%;

        border-color: transparent;

        // Just hides the resize handle and disables
        // *user* resizing of the box. The auto-resize
        // code still works with this property.
        resize: none;

        &::placeholder {
            color: transparentize($content-color, $amount: .4);
        }

        &:active, &:hover, &:focus {
            outline: none;

            // border-color: $highlight-color;
        }

        &.subjectField {
            margin-bottom: 0.25rem;
        }
    }

    .inputs {
        display: flex;
        flex: 1;
        width: 100%;

        flex-direction: row;
        justify-content: space-between;
        align-items: center; //flex-start;
    }

    .attachmentsRow {
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        // padding: .5rem 0;

        // margin: 0.25rem 0.25rem 0.25rem 0; 
        // border-radius: 0.25rem;

        .attachment {
            border-radius: 0.25rem;


            .icon {
                border: .111rem solid $highlight-color;
                background: white; //lighten($highlight-color, 45%);
                color: $highlight-color;
                border-radius: 0.25rem;
                padding: .5rem .5rem .25rem;

                position: relative;
            
                .cancelIcon {
                    position: absolute;
                    top: -.5rem;
                    right: -.5rem;
                    background: $highlight-color;
                    border-radius: 50%;
                    width:  1.5rem;
                    height: 1.5rem;
                    overflow: none;

                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    color: white;
                }
            }
            
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            max-width: 7rem;
            padding: 0.75rem 0.5rem 0.5rem;

            .label {
                font-size: .625rem;
                text-align: center;
                padding-top: 0.25rem;
                color: white;

                @include force-word-break();
            }
        }
    }

    .rteEditor {
        width: 100%;
        // border-radius: $input-border-radius;
        min-height: 5rem;
        max-height: 50vh;
        overflow: auto;
        // margin: 0 3rem 0 .25rem;
        margin: 0 0rem 0 0;

        display: flex;
        // put the toolbar BELOW the editor
        flex-direction: column-reverse;

        background: $content-bg;
        color: $content-color;
        font-family: 'Roboto', $font-family-base !important;
        border: none;

        :global(.public-DraftEditorPlaceholder-root) {
            color: transparentize($content-color, $amount: .4);
        }

        [class^=EditorToolbar__root] {
            // background: rgba(0,0,0,0.125);
            background: $highlight-color;
            color: $content-color;
            border: 0;

            padding: .4rem 0 0 .25rem;
            margin: .25rem 0 -.25rem 0;

            select {
                // Icons are hardcoded black, so match 
                // those rather than $content-color
                color: black;
            }

            button {
                // Remove border/background from buttons
                background: none;
                border: none;

                &:hover {
                    // background: rgba(0,0,0,0.125);
                    background: darken($color: $highlight-color, $amount: 5%);
                }
            }

            [class^=Dropdown__root] {
                // Better align the value display with the buttons in the row
                transform: translateY(-.125rem);

                // Make the value display look like the button
                [class^=Dropdown__value] {
                    border: none;
                    background: none;
                }
            }


        }

        [class^=RichTextEditor__editor] {
            flex: 1;
        }

        // [class^=RichTextEditor__paragraph] {
        //     margin: 0.5rem 0 !important;
        // }

        // [contenteditable=true] {
        //     font-family: inherit;
        // }
    }

    .channelSelect {
        padding: 0 .25rem;
        // :global(.MuiInputBase-root),
        // :global(.MuiFormLabel-root) {
        //     font-size: .55rem;
        // }

        :global(.MuiInputBase-root) {
            :global(.MuiSelect-root), svg {
                color: white;

            }
            &::before, &::after {
                display: none;
            }
        }
    }

    :global(.MuiIconButton-root) {
        padding: .25rem .25rem;

        :global(.MuiSvgIcon-root) {
            color: white;
            font-size: 1.25rem;
        }
    }

    .addImageBtn {
        margin: 0 .25rem 0 -.25rem;
    }

    .sendMessageBtn {
        width: 3rem;
        padding: .25rem .25rem 0 .5rem;
        border-radius: 0;

        :global(.MuiSvgIcon-root) {
            color: white;
            font-size: 1.25rem;
            transform: translateY(-.2rem);
        }
    }
}