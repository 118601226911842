@import '../styles/common.scss';

.root {
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	position: relative;

	&.doublePaneView {
		// width: 33%;
	}

	.conversation {
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;

		padding: .75rem .5rem; //0.25rem 0.5rem 0.25rem 0.5rem;

		line-height: 1.1;

		&:hover, &:focus, &:active {
			background: transparentize($color: $highlight-color, $amount: .825);
		}

		$avatar-wrap-width: 3rem;
		.avatarWrap {
			width: $avatar-wrap-width;
			// padding-top: .2rem;
		}


		.infoWrap {
			display: flex;
			flex-direction: column;
            flex: 1;

			width: calc(100% - #{$avatar-wrap-width});

			opacity: 0.9;

			padding-top: .2rem;

			color: $content-color;

			.topWrap {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				
				.name {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: baseline;
					flex: 1;
					width: calc(100% - #{$avatar-wrap-width});

					// font-weight: 400;
					// Google Messenger for web font styling
					font: 400 15px/20px var(--headline-8-font-family,"Google Sans",Roboto,arial,sans-serif,"NotoColorEmoji","apple color emoji","windows emoji","windows symbol");
					margin-right: .25rem;

					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;

				}

				.timestamp {
					// font-weight: 400;
					font: 400 12px/16px Roboto,Helvetica Neue,sans-serif,NotoColorEmoji,apple color emoji,windows emoji,windows symbol;
					letter-spacing: .3px;
					text-align: end;
				}
			}

			.previewWrap {
				// font-weight: 400;
				width: 100%;

				// Google Messenger for web font styling
				font: 400 14px/20px Roboto,Helvetica Neue,sans-serif,NotoColorEmoji,apple color emoji,windows emoji,windows symbol;
				letter-spacing: .2px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		&.hasNewMessages {
			$weight: 700;
			font-weight: $weight;

			.infoWrap {
				opacity: 1;

				.topWrap {
					.name {
						font-weight: $weight;
					}
				}
			}

			.previewWrap {
				font-weight: $weight;
			}

			.badge {
				font-size: .75rem;
				padding: .25rem .35rem .2rem .25rem;
				line-height: .95;
			}

		}

	}


	.addConvoFab {
		position: absolute;
		right:  .75rem;
		bottom: .75rem;

		background: $highlight-color;
		color: white;

		z-index: 99;

		width: 3.5rem;
		height: 3.5rem;

		&:hover {
			background: darken($highlight-color, 10%);
		}
	
	}
}

body:global(.PrimaryNav-visible) {
	.root {
		// height: calc(100vh - var(--primary-nav-safe-area) + 1.5rem);
		padding-bottom: 0; //calc(var(--primary-nav-safe-area) - 2.5rem);
	}

	&:global(.PrimaryNav-landscape) {
		.root {
			padding-bottom: 0;
		}
	}
}