@import './styles/common.scss';

.root,
.root:global(.MuiButton-root) {
	border-radius: 1rem;
	width: 10rem;
	text-transform: none;
	background: $brand-primary;

	&:hover {
		background: transparentize($brand-primary, .5);
	}
}