@import '../styles/common.scss';

.root {
	color: $content-color;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	h1 {
		text-align: center;
	}
	


}