@import '../styles/common.scss';

.root {
	height: 100%;
	color: white;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.header,
	.body {
		height: 10rem;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
	}

	.header {
		padding-bottom: 2rem;

		:global(.SimpleBanner-root) {
			font-size: 1.5rem;
			margin: 0;
		}
	}

	.body {
		height: calc(100% - 10rem);
		justify-content: center;
		position: relative;
		width: 100%;
		

		h3 {
			font-size: .75rem;
			text-align: center;
			line-height: 1;
			font-weight: normal;
			opacity: 0.5;

			margin: 0rem 2rem 1rem;
		}

		h2 {
			font-size: 1.25rem;
			text-align: center;
			line-height: 1;
			opacity: 0.85;

			margin: 0rem 2rem 1rem;
		}

		code {
			color: $brand-secondary;
			max-width: 85%;
			@include force-word-break();
			font-size: .625rem;
			line-height: 1;
			background: rgba(255,255,255,0.05);
			padding: .5rem;
			border-radius: .25rem;
			margin-bottom: 2rem;
		}
	}

	:global(.CustomButton-root) {
		margin-bottom: .5rem;

		&.btnPin {
			margin-bottom: 2rem;
		}

		&.btnLogin,
		&.btnSignup,
		&.btnNewProj {
			background: rgba(255,255,255,0.125);
			
			&:hover {
				background: rgba(255,255,255,0.075);
			}
		}

		&.btnNewProj {
			line-height: 1.1;
		}

		&.btnFb, &.btnAnon, &.btnLoginSubmit {
			// width: calc(100% - 3rem);
			width: 13rem;
			
		}
	}

	:global(.MuiInput-root) {
		input {
			text-align: center;
		}

		margin-bottom: 1rem;

		width: 12rem;
	}
}

.CustomCard {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	pointer-events: none;
	opacity: 0;

	overflow: auto;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	.internalWrapper {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		max-width: 600px;
	}

	@include transition-all(.1s);
	transform: translateX(-100%);
	
	&.visible {
		opacity: 1;
		transform: translateX(0);
		pointer-events: all;
	}
}