@import '../styles/common.scss';

.root {
	height: 100vh; //calc(100vh - 3rem);
	display: flex;
	flex-direction: column;

	&.doublePaneView {
		width: 67%;
	}

	.listWrapper {
		flex: 1;
		overflow: hidden;
		height: 100%;
		// margin-top: 2.5rem;

		.loadingWrap {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			padding: 2rem;
			
			:global(.MuiCircularProgress-root) {
				color: $highlight-color;
			}
		}
	}

	.header {
		background: $highlight-color;
		color: transparentize(invert($foreground-color), .25);
		padding: .5rem;
		box-shadow: 0 0 .5rem rgba(0,0,0,0.5);
		position: relative;
		z-index: 100;
		
		h3 {
			margin: 0;
			padding: 0;
		}
	}
}

body:global(.PrimaryNav-visible) {
	.root {
		height: calc(100vh - var(--primary-nav-safe-area) + 1.5rem);
	}

	&:global(.PrimaryNav-landscape) {
		.root {
			height: 100vh;
		}
	}
}