@import '../styles/common.scss';

.root {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	color: $content-color;

	padding-top: 3rem;
	
	.fieldList {
		// margin-top: 2.5rem;
		margin-bottom: 0;
		padding-bottom: 0;

		width: 100%;
		max-width: 25rem;
		color: $content-color;
		background-color: transparent;

		.myListItem {
			// default for mui list items is flex-start
			justify-content: space-between;
			display: flex; // for use with subheader
			flex-direction: row;  // for use with subheader
			align-items: center;  // for use with subheader
		
			color: $content-color;
	
			min-height: 2rem;

			.value {
				font-weight: bold;
			}

			&:hover, &:focus, &:active {
				background: transparentize($color: $highlight-color, $amount: .825);
			}

			.endButtonWithLabel {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-end;
				flex: 1;
				margin-right: -.5rem;
		
				:global(.MuiIconButton-root) {
					// padding-top: .25rem;
					// padding-bottom: .25rem;
					padding-top: 0;
					padding-bottom: 0;
				}
			}
	
		}
	
	}
}