@import '../styles/common.scss';

.root {

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	color: $content-color;

	padding-top: 3rem;

	overflow-x: hidden;
	
	.fieldList {
		// margin-top: 2.5rem;
		margin-bottom: 0;
		padding-bottom: 0;

		width: 100%;
		max-width: 25rem;
		color: $content-color;
		background-color: transparent;
		
		.myListItem {
			// default for mui list items is flex-start
			justify-content: space-between;
			display: flex; // for use with subheader
			flex-direction: row;  // for use with subheader
			align-items: center;  // for use with subheader
		
			color: $content-color;
	
			min-height: 2rem;

			.value {
				font-weight: bold;

				.emailCode {
					color: $content-color;
				}

				.emailDomain {
					opacity: 0.6;
				}
			}

			&:hover, &:focus, &:active {
				background: transparentize($color: $highlight-color, $amount: .825);
			}

			.endButtonWithLabel {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-end;
				flex: 1;
				margin-right: -.5rem;

				:global(.MuiIconButton-root) {
					// padding-top: .25rem;
					// padding-bottom: .25rem;
					padding-top: 0;
					padding-bottom: 0;
				}
			}
	
		}

		.listDivider {
			border-top: 1px solid $content-color;
			opacity: 0.6;
			margin: .75rem -.25rem;
		}
	
	}

	// height: 100%;
	// color: $content-color;

	// .content {
	// 	display: flex;
	// 	flex-direction: column;
	// 	justify-content: flex-start;
	// 	align-items: center;

	// 	padding: 1rem 0;
	// }

	// .userDisplay {
	// 	margin: 0;
	// 	opacity: 0.625;
	// 	@include force-word-break();
	// 	text-align: center;
	// 	font-size: .625rem;
	// }

	// .workspaceIdDisplay {
	// 	margin: .5rem 0 0 0;
	// 	text-align: center;
	// 	font-size: 1.5rem;
	// }

	// // .header,
	// // .body {
	// // 	height: 10rem;
	// // 	display: flex;
	// // 	flex-direction: column;
	// // 	justify-content: flex-end;
	// // 	align-items: center;
	// // }

	// .qrCodeWrap {
	// 	background: white;
	// 	$pad: 1rem;
	// 	$adjust: 8px;
	// 	padding: calc(#{$pad} + #{$adjust}) calc(#{$pad}) #{$pad} calc(#{$pad} + #{$adjust / 2});
	// 	border-radius: 1rem;
	// 	margin: 1rem;
	// }

	// .qrCodeHint {
	// 	font-size: .75rem;
	// 	margin: 0 1.5rem 1.25rem;
	// 	opacity: 0.625;
	// 	text-align: center;

	// 	a {
	// 		color: white;
	// 		font-weight: bold;
	// 	}

	// 	:global(.MuiSvgIcon-root) {
	// 		font-size: .9rem;
	// 	}
	// }

	:global(.CustomButton-root) {
		margin-bottom: .5rem;

		&.btnProj {
			margin-bottom: 1.25rem;
		}

		&.btnLogout {
			background: rgba(255,255,255,0.125);

			&:hover {
				background: rgba(255,255,255,0.075);
			}
		}
	}

	.cameraSelect {
		margin: 1rem;
	}

	.streamUrlWidget, .goLiveWidget {
		margin: 1rem;
		width: calc(100% - 2rem);
	}
}


body:global(.PrimaryNav-visible) {
	.content {
		padding-bottom: var(--primary-nav-safe-area);
	}

	&:global(.PrimaryNav-landscape) {
		.content {
			padding-bottom: 1rem;
		}
	}
}