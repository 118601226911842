@import './styles/common.scss';

.root {
    background: $highlight-color;
    color: transparentize(invert($foreground-color), .25);
    // padding: .5rem;
    box-shadow: 0 0 .5rem rgba(0,0,0,0.5);
    // position: relative;
    &.position-fixed {
        position: fixed;
    }
    z-index: 100;
    flex: 0 1;
    width: 100%;

    .bar {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
        h3 {
            margin: 0;
            padding: .5rem;

            @include force-word-break();
            text-align: center;
        }

        .moreMenu {
            position: relative;
        }

        svg {
            color: white;
        }

        .missingMoreMenu {
            /* size of an icon button */
            width: 44px;
            height: 44px;
        }
    }
}

body:global(.PrimaryNav-visible) {
	// .root {
	// 	width: 100%;
	// }

	&:global(.PrimaryNav-landscape) {
		.root.position-fixed {
			width: calc(100% - var(--primary-nav-safe-area) + 1rem);
		}
	}
}