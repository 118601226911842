@import '../styles/common.scss';

.root {
	display: flex;
	flex-direction: column;
	flex: 1;

	.searchBar {
		// background: darken(rgb(255,250,255), 1%);		
		// color: rgba(0, 0, 0, 0.54);
		background: $highlight-color; //rgba(0,0,0,0.9);
		color: $foreground-color;

		box-shadow: 
			0 0 10px rgba(0,0,0,0.8),
			0 0 30px rgba(0,0,0,0.4);

		padding: .25rem .25rem;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		
		// :global(.MuiToolbar-root) {
		// 	max-width: 640px;
		// }

		:global(.MuiIconButton-root) {
			display: flex;
			flex: 0;
			padding: .5rem;
		}

		:global(.MuiSvgIcon-root) {
			color: $content-color;
		}

		.searchWrap {
			// max-width: 640px;
			width: 100%;		

			display: flex;
			flex: 1;
			justify-content: flex-start;
			flex-direction: row;
			align-items: center;

			padding-right: .5rem;
			

			.searchButton {
				margin-left: .5rem;
				margin-right: .4rem;
				svg {
					fill: $content-color;
					// margin: .5rem;
					opacity: 0.95;
				}
			}

			.search {
				flex: 1;

				input {
					color: $content-color;

					&::placeholder {
						color: transparentize($content-color, .25);
					}
				}
			}
		}
	}

	:global(.MuiInputLabel-root) {
		margin: .75rem 0 -.5rem .35rem;
		font-size: .75rem;

		& + :global(.MuiFormHelperText-root) {
			font-size: .5rem;
			margin: 0 0 -.1rem .35rem;
			line-height: 1;
		}
	}

	.person {
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		
		color: $content-color;

		padding: 0.25rem 0.5rem 0.25rem 0.5rem;

		line-height: 1.1;

		&:hover, &:focus, &:active {
			background: transparentize($color: $highlight-color, $amount: .825);
		}

		$avatar-wrap-width: 3rem;
		.avatarWrap {
			width: $avatar-wrap-width;
			// padding-top: .2rem;
		}


		.infoWrap {
			display: flex;
			flex-direction: column;
            flex: 1;

			width: calc(100% - #{$avatar-wrap-width});

			opacity: 0.9;

			padding-top: .2rem;

			color: $content-color;

			.topWrap {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				
				.name {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: baseline;
					flex: 1;
					width: calc(100% - #{$avatar-wrap-width});

					// font-weight: 400;
					// Google Messenger for web font styling
					font: 700 15px/16px var(--headline-8-font-family,"Google Sans",Roboto,arial,sans-serif,"NotoColorEmoji","apple color emoji","windows emoji","windows symbol");
					margin-right: .25rem;

					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;

				}

				.timestamp {
					// font-weight: 400;
					font: 400 12px/16px Roboto,Helvetica Neue,sans-serif,NotoColorEmoji,apple color emoji,windows emoji,windows symbol;
					letter-spacing: .3px;
					text-align: end;
				}
			}

			.previewWrap {
				// font-weight: 400;
				width: 100%;

				// Google Messenger for web font styling
				font: 400 14px/16px Roboto,Helvetica Neue,sans-serif,NotoColorEmoji,apple color emoji,windows emoji,windows symbol;
				letter-spacing: .2px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				.phoneNum {
					font-family: monospace;
				}
			}
		}

		&.hasNewMessages {
			font-weight: bold;

			.infoWrap {
				.topWrap {
					.nameWrap {
						.name {
							font-weight: bold;
						}
					}
				}
			}

			.previewWrap {
				font-weight: bold;
			}

		}

	}


	.addConvoFab {
		position: fixed;
		right:  .75rem;
		bottom: 3.5rem;

		background: $highlight-color;
		color: white;

		z-index: 99;

		width: 2.5rem;
		height: 2.5rem;

		&:hover {
			background: darken($highlight-color, 10%);
		}
	
	}
}


body:global(.PrimaryNav-visible) {
	.root {
		// height: calc(100vh - var(--primary-nav-safe-area) + 1.5rem);
		padding-bottom: calc(var(--primary-nav-safe-area) - 1rem);
	}

	&:global(.PrimaryNav-landscape) {
		.root {
			padding-bottom: 0;
		}
	}
}