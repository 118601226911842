@import '../styles/common.scss';

.root {
    // border-radius: .5rem !important;

    &.inbound {
        background-color: transparentize($content-color, .75) !important;

        :global(.MuiCircularProgress-root) {
            color: $highlight-color;
        }
    }

    &:not(.inbound) {
        background-color: transparentize($highlight-color, .125) !important;

        :global(.MuiCircularProgress-root) {
            color: $content-color;
        }
    }
}