@import '../styles/layoutCommon.scss';

.banner {
	@include noselect();

	// margin: 3.5rem 0rem -3.5rem;
	margin: 2rem 1rem .5rem;
	// border-bottom: .25rem solid white;
	font-size: 1rem;
	font-weight: 700;
	letter-spacing: -1px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	.d {
		color: rgba(157,136,178,1);
	}

	.ecidr {
		color: rgba(157,136,178,0.75);
	}
}
