@import '../../styles/common.scss';
@import '../chatCommon.scss';

.scrollContainer {
	height: 100% !important;
	width: 100% !important;

	// background: lighten($highlight-color, 47%);
}

$radius: 1.25rem;

.messagesList {
	padding-bottom: 1.5rem;

	height: 100%;
	color: $content-color;


	// Put this here instead of deeper in the tree because
	// for some reason deeper in the tree it wasn't getting applied
	.moreMenu {
		position: relative;
		opacity: 0;
		pointer-events: none;
		align-self: center;

		transition: opacity $default-transition-time $my-cubic-transition;

		transform: translateY(.1rem);

		&.forceVisible {
			opacity: 1;
			pointer-events: all;
		}

		svg {
			color: $content-color;
		}
	}

	.message {
		display: flex;
		flex: 0 0 auto;
	}

	// &.slackStyle .message {
	.message {
		// display: flex;
		// flex-direction: column;
		width: 100%;

		padding: .125rem 0 0rem .5rem;

		// background-color: $highlight-color;// #3578E5;
		// border-radius: $radius/2;
		// color: #FFFFFF;

		.newMessage {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			// margin-bottom: -.125rem;
			padding-top: .5rem;
			
			.label {
				font-size: .625rem;
				color: red;
				padding-right: .125rem;
				font-weight: 700;
			}
			
			.line {
				background: red;
				flex: 1;
				width: 100%;
				height: 1.5px;
			}
		}

		.timestampHeader {
			width: 100%;
			align-items: center;
			display: flex;
			justify-content: center;
			padding-top: 1rem;

			.label {
				// Google Messages for Web uses this font
				font: 500 12px/16px Roboto,Helvetica Neue,sans-serif,NotoColorEmoji,apple color emoji,windows emoji,windows symbol;
				letter-spacing: .3px;
				color: transparentize($content-color, $amount: .25);
				margin: .5rem;
				text-align: center;
				white-space: pre-wrap;
				word-wrap: break-word;
			}
		}

		.loading {
			display: flex;
			flex: 1;
			width: 100%;

			padding: 1rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			:global(.MuiCircularProgress-root) {
				color: $highlight-color;
			}

			label {
				margin: 0;
				padding: 1rem 0 0;
				opacity: 0.4;
			}
		}

		.bodyWrap {
			width: 100%;
			display: flex;
			flex-direction: column;

			// padding-top: .5rem;

			.avatarWrap {
				display: flex;
				width: 100%;
				flex-direction: row;
				justify-content: flex-start;
				align-items: flex-end;

				position: relative;
				border-radius: $radius;

				&:hover, &:focus, &:active {
					background: transparentize(lighten($highlight-color, 33%), 0.75);

					@include customIgOverlayLight();
					&::after {
						position: absolute;
						opacity: 0.33;
					}

					.moreMenu {
						opacity: 1;
						pointer-events: all;
					}	
				}

				$author-change-pad: .75rem / 2;
				padding: 0 $author-change-pad;

				&.newAuthor {
					padding-top: $author-change-pad;
				}

				&.newAuthorEnd {
					padding-bottom: $author-change-pad;
				}

				.avatar {
					flex: 0;
					opacity: 0;
					width: 5rem;
					// padding-top: .3rem;

					&.showAvatar {
						opacity: 1;
					}
				}

				.contentWrap {
					width: 100%;
					display: flex;
					flex-direction: column;	
					flex: 1;

				

					.textContentWrap {
						width: 100%;
						display: flex;
						flex-direction: column;

						.mediaAttachments {
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							
							img, video {
								object-fit: cover;
								max-height: 240px;
								max-width: 320px;
								border-radius: $radius;
								// overflow: hidden;
								color: transparent;
								background: transparentize($content-color, $amount: .75);
								font-size: 0;
								margin-right: .25rem;
							}
						}

						video {
							object-fit: contain;
							background: rgba(0,0,0,0.125);

							&:focus, &:active, &:hover {
								outline: none;
							}
						}

						.textContent {
							// background-color: $highlight-color;// #3578E5;
							// border-radius: .25rem;
							// color: #FFFFFF;
							// font-family: Roboto, sans-serif;
							// padding: 0rem 0 .25rem;
							white-space: pre-line;

							// font-size: $text-size;
							// line-height: 1.25;

							// Same as Google Messages on Web
							font: 400 15px/20px Roboto,Helvetica Neue,sans-serif,NotoColorEmoji,apple color emoji,windows emoji,windows symbol;

							// padding-bottom: .25rem;

							.emailBodyHtml {
								white-space: initial;
								// line-height: 1.125;

								ol > li,
								ul > li,
								p {
										margin: 0 0 .5rem;
										white-space: pre-line;
								}

								// p {
								// 	margin: 0 0 .5rem;
								// 	white-space: pre-line;
								// }
							}
						}
					}

					.whoAndWhen {
						opacity: 0.75;
						// font-size: 0.625rem;
						text-align: left;
						// padding-top: .75rem;

						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: baseline;

						// color: $highlight-color;
						// opacity: 0.9;

						line-height: 1.1;

						.who {
							font-weight: bold;
							padding: 0 .25rem 0 0;
							font-size: 1.05rem;
							flex: 1;

							// height: 1rem;
							overflow: hidden;

							// $mask: linear-gradient(to right,
							// 	rgba(0, 0, 0, 1) 0%,
							// 	rgba(0, 0, 0, 1) 90%,
							// 	rgba(0, 0, 0, 0) 100%);

							// mask-image: $mask;
							// -webkit-mask-image: $mask;

							text-overflow: ellipsis;
							white-space: nowrap;


							@include force-word-break();
							
							// margin-left: .5rem;
						}

						.when {
							font-weight: 400;
							font-size: .5rem;
							padding: 0 .125rem 0 0.125rem;
						}

						.channel {
							padding: 0 .25rem 0 0.25rem;
							font-size: .5rem;
							font-weight: bold;
							cursor: default;
						}
					}

					.subject {
						opacity: 1; //0.75;
						font-weight: 500;
						// font-size: .825rem;
						// margin-top: -.125rem;
						// margin-top: .5rem;
						// padding-top: .5rem;
						padding: .5rem $radius / 2 0;
						margin-bottom: .25rem;
					}

					.subject + .whoAndWhen {
						padding-top: 0;
					}
				}
			}
		}

		&.outbound {
			.whoAndWhen {
				// opacity: 0.5;
				// color: black;

				color: $highlight-color;
				opacity: 0.9;
			}

			.subject {
				color: lighten($highlight-color, 25%);
			}
			
			.textContent {
				color: darken($highlight-color, 15%);
			}
			// .bodyWrap {
				// background-color: transparent;	
				// color: black;

				// background-color: $highlight-color;// #3578E5;
				// color: #FFFFFF;
				// padding-left: .25rem;

				// margin-left: -.25rem;
				// border-radius: .25rem;
			// }
		}

		.fileAttachments {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			flex-wrap: wrap;
			padding: .5rem 0;

			margin: 0.25rem 0.25rem 0.25rem 0; 
			border-radius: $radius/2;
			// background: #ddd;

			.attachment {
				border-radius: $radius/2;

				.icon {
					border: .111rem solid $highlight-color;
					background: lighten($highlight-color, 45%);
					color: $highlight-color;
					border-radius: $radius/2;
					padding: 1rem 1rem .75rem;
				}
				
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				max-width: 8rem;
				padding: 0.75rem 0.5rem 0.5rem;

				.label {
					font-size: .625rem;
					text-align: center;
					padding-top: 0.25rem;

					@include force-word-break();
				}
			}
		}
		
	}

	&.messengerStyle .message {


		.bodyWrap {
			align-items: flex-start;
			width: 100%;

			.avatarWrap {
				max-width: 82.5%;
				width: auto;
				
				.avatar {
					padding-right: .5rem;
				}
				// flex-direction: row-reverse;

				// .avatar {
				// }

				.textContentWrap {

					.textContent {

						background-color: transparentize($content-color, .75);
						border-radius: $radius * .25;

						padding: $radius/3 $radius/2;
						// font-size: $text-size;

						.emailBodyHtml {
							margin: .25rem 0 -.25rem;
						}
					}
				}

				&.newAuthor {
					.textContentWrap {
						.textContent {
							border-top-left-radius: $radius;
							border-top-right-radius: $radius;
						}
					}
				}

				&.newAuthorEnd {
					.textContentWrap {
						.textContent {
							border-bottom-left-radius: $radius;
							border-bottom-right-radius: $radius;
						}
					}
				}

			}
		}

		&.outbound {

			.bodyWrap {
				align-items: flex-end;
				width: 100%;
		
				// padding: .125rem;
				padding-right: .5rem;

				.avatarWrap {
					flex-direction: row-reverse;

					.avatar {
						padding: 0 0 0 .5rem;
					}
					
					.whoAndWhen {
						flex-direction: row-reverse;

						.who {
							text-align: right;
							width: auto;
							flex: initial;
						}
					}

					.subject {
						text-align: right;
						padding-right: .25rem;
					}
					
					.textContentWrap {
						align-items: flex-end;

						.mediaAttachments {
							img, video {
								margin-right: 0;
								margin-left: 0.25rem;
							}
						}
						
						.textContent {
							// background-color: $highlight-color;// #3578E5;
							background-color: transparentize($highlight-color, .125);
							// border-radius: $radius;
							color: #FFFFFF;
							// font-family: Roboto, sans-serif;
							margin-right: -.125rem;


						}
					}
				}
			}
		}

		// &.inbound {
		// 	justify-content: flex-start;


		// 	.bodyWrap {
		// 		.avatarWrap {
		// 			flex-direction: row;
		// 		}
		// 	}


		// 	.textContent {
		// 		background-color: #FFFFFF;	
		// 		// background: lighten($highlight-color, 55%);
		// 		color: black;
		// 	}

		// 	.whoAndWhen {
		// 		text-align: left;
		// 	}
		// }
	}
}